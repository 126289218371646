// 记录本服务接口
import  request from "../utils/request";

// 获取初始数据 
export const getNoteBookInit = (params) => {
    return request({
        method: 'get',
        url: `/notebook/noteBookInit`,
        params
    })
}

// 新建记录本
export const addNoteBook = (data) => {
    return request({
        method: 'post',
        url: `/notebook/addNoteBook`,
        data
    })
}

// 获取记录本列表
export const getNoteBookList = (params) => {
    return request({
        method: 'get',
        url: `/notebook/myNoteBookList`,
        params
    })
}

// 删除记录本
export const cancelNoteBook = (params) => {
    return request({
        method: 'get',
        url: `/notebook/cancelNoteBook`,
        params
    })
}

// 获取记录本详情
export const getNoteBookDetail = (params) => {
    return request({
        method: 'get',
        url: '/notebook/detail',
        params
    })
}