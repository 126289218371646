<template>
    <a-modal :width="600" :visible="visible" @cancel="handleCancel" title="新建记录本">
    <a-spin :spinning="loading">
        <a-form-model style="width: 85%; margin: 0 auto;" ref="formRef" :model="ruleForm" :rules="rules"
            v-bind="layout">
            <a-form-model-item label="名称" has-feedback prop="noteBookName">
                <a-input v-model="ruleForm.noteBookName" placeholder="请输入记录本名称（30字以内）" autocomplete="off" :maxLength="30">
                </a-input>
            </a-form-model-item>
            <a-form-model-item label="描述" has-feedback prop="description">
                <a-input v-model="ruleForm.description" placeholder="请输入(100字以内)" autocomplete="off" type="textarea" :maxLength="100">
                </a-input>
            </a-form-model-item>
            <a-form-model-item label="项目" has-feedback prop="projectId">
                <div style="width: 100%;">
                    <a-select placeholder="请选择" v-model="ruleForm.projectId">
                        <a-select-option v-for="(item,index) in initData.pojectList" :key="item.value">
                        {{ item.text }}
                        </a-select-option>
                    </a-select>
                </div>
                <div>
                    <a @click="addProjectButtonClick"> 新建项目 </a>
                </div>
            </a-form-model-item>
        </a-form-model>
    </a-spin>
    <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button type="primary" :loading="loading" :disabled="loading" class="confirm-btn" @click="handleConfirmClick">
                    确定
                </a-button>
            </div>
        </template>
        <AddProjectModal :visible.sync="addProjectModalVisible" @onAddProject="onAddProject">
		</AddProjectModal>
    </a-modal>
</template>

<script>
import { message } from 'ant-design-vue';
import { watch, ref } from 'vue-demi';
import { getNoteBookInit, addNoteBook } from '../../api/notebook';
import AddProjectModal from '../../views/itemCenter/components/addProjectModal.vue';
import store from '../../store';
const layout = {
    labelCol: {
        span: 6
    },
    wrapperCol: {
        span: 18
    },
}
export default {
    props: ['visible', 'eData'],
    components: {
        AddProjectModal
    },
    setup(props, context) {
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        const formRef = ref(null);

        const ruleForm = ref({
            noteBookName: '',
            description: '',
            projectId: undefined
        });
        const rules = ref({
            noteBookName: {
                required: true,
                message: '请输入',
                whitespace: true
            },
            projectId: {
                required: true,
                message: '请选择项目'
            }
        })
        const loading = ref(false);
        const initData = ref({
            pojectList: [{
                value: '1',
                text:'1'
            }]
        })
        watch(() => props.visible, async (newVal) => {
            if(newVal) {
                loading.value = true;
                formRef.value && formRef.value.resetFields();
                const res = await getNoteBookInit();
                if(res.code === 200) {
                    initData.value = res.data;
                    loading.value = false;
                }
            }
        }, { immediate: true })
        // 确认新建
        const handleConfirmClick = () => {
            formRef.value && formRef.value.validate().then(async (valid) => {
                if(valid) {
                    loading.value = true;
                    const res = await addNoteBook(ruleForm.value);
                    if(res.code === 204 || res.code === 200) {
                        message.success(res.message || '添加成功');
                        context.emit('update:visible', false);
                        context.root.$store.dispatch('trigger/toggleAddNoteBook', res.data)
                    }
                    loading.value = false;
                }
                else {
                    message.error('请检查表格')
                }
            })
        }

       // 新建项目弹窗 可见变量
		const addProjectModalVisible = ref(false);
		// 新建项目按钮点击
		const addProjectButtonClick = () => {
			addProjectModalVisible.value = true;
		}
		// 新建项目回调
		const onAddProject = async () => {
            loading.value = true;
                formRef.value && formRef.value.resetFields();
                const res = await getNoteBookInit();
                if(res.code === 200) {
                    initData.value = res.data;
                    loading.value = false;
                }
        }
        return {
            handleCancel,
            formRef,
            ruleForm,
            layout,
            rules,
            loading,
            initData,
            handleConfirmClick,
            addProjectButtonClick,
            addProjectModalVisible,
            onAddProject
        }
    }
}
</script>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;
    width: 100%;

    .concel-btn {
        background: #eeeeee;
    }
}
</style>