<template>
    <a-modal :width="600" :visible="visible" @cancel="handleCancel" title="新建实验">
        <a-spin :spinning="loading" tip="正在加载中">
            <a-form-model  style="width: 85%; margin: 0 auto;" ref="formRef" :model="ruleForm" :rules="rules"
                v-bind="layout">
                <a-form-model-item label="实验名称" has-feedback prop="title">
                    <a-input v-model="ruleForm.title" placeholder="请输入标题（30字以内）" autocomplete="off" :maxLength="30">
                    </a-input>
                </a-form-model-item>
                <a-form-model-item label="记录本" has-feedback prop="noteBookId">
                    <div style="width: 100%;">
                        <a-select placeholder="请选择" v-model="ruleForm.noteBookId" @select="handleNotebookSelected">
                            <a-select-option v-for="(item, index) in initData.noteBookList" :key="item.value">
                                {{ item.text }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div style="line-height: 1; margin-top: 5px;">
                        <a type="link" @click="handleAddNewNoteBook"> 新建记录本 </a>
                    </div>
                </a-form-model-item>
                <a-form-model-item label="实验模板" has-feedback prop="experimentTemplateId">
                    <a-select placeholder="请选择" v-model="ruleForm.experimentTemplateId">
                        <a-select-option v-for="(item, index) in initData.experimentTemplateList" :key="item.value">
                            {{ item.text }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
            <div class="tip" style="display: flex;justify-content: center;">
                未找到合适的模板，可到<a :href="getTemplateCenter" target="_blank">模板中心</a>查找你想要的模板
            </div>
        </a-spin>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button type="primary" :loading="loading" :disabled="loading" class="confirm-btn"
                    @click="handleConfirmClick">
                    新建并打开
                </a-button>
            </div>
        </template>
        <AddNewNotebookModal :visible.sync="addNewNoteBookModalVisible"></AddNewNotebookModal>
    </a-modal>
</template>

<script>
import { useDocumentVisibility } from '@vueuse/core';
import { message } from 'ant-design-vue';
import { async } from 'q';
import { watch, ref, computed } from 'vue-demi';
//import { getNoteBookInit, addNoteBook } from '../../api/notebook';
import { getAddExperimentInitData, addNewExperiment, getExperimentTemplateByNotebookId } from '../../api/experiment';
import globalVueThis from '../../main';
import AddNewNotebookModal from './addNewNotebookModal.vue';
const layout = {
    labelCol: {
        span: 6
    },
    wrapperCol: {
        span: 18
    },
}
export default {
    components: {
        AddNewNotebookModal
    },
    props: ['visible', 'eData', 'noteBookId'],
    setup(props, context) {
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        const formRef = ref(null);

        const ruleForm = ref({
            title: '',
            experimentTemplateId: undefined,
            noteBookId: undefined
        });
        const rules = ref({
            title: {
                required: true,
                message: '请输入',
                whitespace: true
            },
            experimentTemplateId: {
                required: true,
                message: '请选择'
            },
            noteBookId: {
                required: true,
                message: '请选择'
            }
        })
        const loading = ref(false);
        const initData = ref({
            // 模板列表
            experimentTemplateList: [],
            // 记录本列表
            noteBookList: []
        })
        watch(() => props.visible, async (newVal) => {
            if (newVal) {
                /* loading.value = true;
                formRef.value && formRef.value.resetFields();
                const res = await getAddExperimentInitData();
                if(res.code === 200) {
                    initData.value = res.data;
                    initData.value.experimentTemplateList = [];
                    if(props.noteBookId) {
                        ruleForm.value.noteBookId = props.noteBookId;
                        handleNotebookSelected(ruleForm.value.noteBookId)
                    }
                    loading.value = false;
                } */
                load();
            }
        }, { immediate: true })
        // 确认新建
        const handleConfirmClick = () => {
            formRef.value && formRef.value.validate().then(async (valid) => {
                loading.value = true;
                if (valid) {
                    const res = await addNewExperiment(ruleForm.value);
                    if (res.code === 204 || res.code === 200) {
                        message.success(res.message || '添加成功');
                        context.emit('update:visible', false);
                        context.root.$store.dispatch('trigger/toggleAddExperiment');
                        const { $router } = context.root;
                        const href = $router.resolve({
                            name: 'experiment',
                            query: {
                                id: res.data.id,
                                mode: 'edit',
                                projectId: res.data.projectId
                            }
                        });
                        window.open(href.href, '_blank')
                    }
                }
                else {
                    message.error('请检查表格')
                }
                loading.value = false;
            })
        }
        // 记录本选择事件
        const handleNotebookSelected = async (value) => {
            loading.value = true;
            ruleForm.value.experimentTemplateId = undefined;
            const res = await getExperimentTemplateByNotebookId({
                noteBookId: value
            });
            if (res.code === 200) {
                initData.value.experimentTemplateList = res.data;
                loading.value = false;
            }
        }
        const getTemplateCenter = computed(() => {
            const href = globalVueThis.$router.resolve({
                name: 'templateCenter'
            })
            return href.href;
        })

        const addNewNoteBookModalVisible = ref(false);

        const handleAddNewNoteBook = () => {
            addNewNoteBookModalVisible.value = true;
        }

        watch(() => addNewNoteBookModalVisible.value, (newVal) => {
            if (!newVal) {
                load();
            }
        })

        const load = async () => {
            loading.value = true;
            formRef.value && formRef.value.resetFields();
            const res = await getAddExperimentInitData();
            if (res.code === 200) {
                initData.value = {
                    noteBookList: res.data,
                    experimentTemplateList: []
                };
                initData.value.experimentTemplateList = [];
                if (props.noteBookId) {
                    ruleForm.value.noteBookId = props.noteBookId;
                    handleNotebookSelected(ruleForm.value.noteBookId)
                }
                loading.value = false;
            }
        }

        const visiblility = useDocumentVisibility();
        watch(() => visiblility.value, async (newVal) => {
            if (newVal === 'visible' && props.visible) {
                //load();
                loading.value = true;
                const res = await getAddExperimentInitData();
                if (res.code === 200) {
                    initData.value = {
                        noteBookList: res.data,
                        experimentTemplateList: []
                    };
                    initData.value.experimentTemplateList = [];
                    if (ruleForm.value.noteBookId) {
                        //ruleForm.value.noteBookId = props.noteBookId;
                        handleNotebookSelected(ruleForm.value.noteBookId)
                    }
                    else {
                        
                    }
                    loading.value = false;
                }
            }
        })
        return {
            handleCancel,
            formRef,
            ruleForm,
            layout,
            rules,
            loading,
            initData,
            handleConfirmClick,
            getTemplateCenter,
            handleNotebookSelected,
            addNewNoteBookModalVisible,
            handleAddNewNoteBook,
        }
    }
}
</script>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;
    width: 100%;

    .concel-btn {
        background: #eeeeee;
    }
}
</style>